.pswp__bg {
  background-color: #ffffff !important;
}

.pswp__top-bar {
  background: rgba(0, 0, 0, 0) !important;
  padding: 10px 20px !important;

  .pswp__button {
    background-color: rgba(180, 180, 180) !important;
    color: rgba(60, 60, 60) !important;
    border-radius: 50px !important;
    margin: 10px 5px !important;
  }
}

.pswp__button {
  &:before {
    border-radius: 50px !important;
  }
}

.pswp__ui--over-close {
  .pswp__button--close {
    opacity: 0.75 !important;
  }
}

.pswp__img--placeholder--blank {
  background: none !important;
}
