.carousel-with-custom-dots-horizontal {
  position: initial;
  border-radius: 1rem;
  background-color: #eeeeee;
  margin: inherit !important;
}

@media screen and (max-width: 62em) {
  .swiper-pagination-bullet-active {
    opacity: 1 !important;
  }
  .swiper-pagination {
    width: 100% !important;
    bottom: 10px;
    transform: none !important;
  }

  .swiper-pagination-bullet {
    border: 0;
    height: 8px !important;
    width: 8px !important;
    transform: none !important;
    margin: 0 2px;

    background: #000;
    opacity: 0.2;
    left: 0 !important;
  }

  .carousel-with-custom-dots {
    border-radius: 0 !important;
  }
  .carousel-with-custom-dots-horizontal {
    border-radius: 0px;
  }
}

.multi-carousel-dots {
  width: 100%;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  white-space: nowrap;
  position: relative;
  display: initial;
  margin-bottom: 10px;
  margin-top: 15px;
  text-align: left;
}

.multi-carousel-dots button {
  margin-inline-start: 6px;
  margin-inline-end: 6px;
}

.multi-carousel-dots button:first-child {
  margin-inline-start: 0;
}

.multi-carousel-dots::-webkit-scrollbar {
  display: none;
}

.hide-dots {
  visibility: hidden;
}
