.expert-thumbs .swiper-slide {
  background-color: rgba(217, 217, 217, 0.5);
  border-radius: 50%;
  width: 8px !important;
  height: 8px !important;
  margin: auto;
}

.expert-thumbs .swiper-wrapper {
  width: 200px;
  margin: auto;
}
.expert-thumbs .swiper-slide-thumb-active {
  background: #fff;
  opacity: 1;
}

@media screen and (min-width: 48em) {
  .expert-thumbs {
    display: none;
  }
  .expert-summary {
    display: none;
  }
}
